import styled from 'styled-components';

const Svgwave = () => {
  return (
    <Wrapper>
      <svg
        width='100%'
        height='100%'
        id='svg'
        viewBox='0 0 1440 320'
        xmlns='http://www.w3.org/2000/svg'
        className='transition duration-300 ease-in-out delay-150'
      >
        <path
          d='M 0,400 C 0,400 0,133 0,133 C 193.33333333333331,107.13333333333333 386.66666666666663,81.26666666666667 521,90 C 655.3333333333334,98.73333333333333 730.6666666666667,142.06666666666666 874,155 C 1017.3333333333333,167.93333333333334 1228.6666666666665,150.46666666666667 1440,133 C 1440,133 1440,400 1440,400 Z'
          stroke='none'
          strokeWidth='0'
          fill='#2bffb1ff'
          fillOpacity='1'
          className='transition-all duration-300 ease-in-out delay-150 path-0'
        ></path>
        <path
          d='M 0,400 C 0,400 0,266 0,266 C 182,283.6 364,301.2 502,302 C 640,302.8 734,286.8 883,278 C 1032,269.2 1236,267.6 1440,266 C 1440,266 1440,400 1440,400 Z'
          stroke='none'
          strokeWidth='0'
          fill='#009760ff'
          fillOpacity='1'
          className='transition-all duration-300 ease-in-out delay-150 path-1'
        ></path>
      </svg>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .path-0 {
    animation: pathAnim-0 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-0 {
    0% {
      d: path(
        'M 0,400 C 0,400 0,133 0,133 C 193.33333333333331,107.13333333333333 386.66666666666663,81.26666666666667 521,90 C 655.3333333333334,98.73333333333333 730.6666666666667,142.06666666666666 874,155 C 1017.3333333333333,167.93333333333334 1228.6666666666665,150.46666666666667 1440,133 C 1440,133 1440,400 1440,400 Z'
      );
    }
    25% {
      d: path(
        'M 0,400 C 0,400 0,133 0,133 C 190.2666666666667,136.86666666666667 380.5333333333334,140.73333333333332 530,150 C 679.4666666666666,159.26666666666668 788.1333333333334,173.93333333333334 933,172 C 1077.8666666666666,170.06666666666666 1258.9333333333334,151.53333333333333 1440,133 C 1440,133 1440,400 1440,400 Z'
      );
    }
    50% {
      d: path(
        'M 0,400 C 0,400 0,133 0,133 C 158.66666666666669,116.73333333333333 317.33333333333337,100.46666666666667 495,104 C 672.6666666666666,107.53333333333333 869.3333333333333,130.86666666666667 1030,139 C 1190.6666666666667,147.13333333333333 1315.3333333333335,140.06666666666666 1440,133 C 1440,133 1440,400 1440,400 Z'
      );
    }
    75% {
      d: path(
        'M 0,400 C 0,400 0,133 0,133 C 132.66666666666669,115.4 265.33333333333337,97.8 408,89 C 550.6666666666666,80.2 703.3333333333333,80.19999999999999 877,89 C 1050.6666666666667,97.80000000000001 1245.3333333333335,115.4 1440,133 C 1440,133 1440,400 1440,400 Z'
      );
    }
    100% {
      d: path(
        'M 0,400 C 0,400 0,133 0,133 C 193.33333333333331,107.13333333333333 386.66666666666663,81.26666666666667 521,90 C 655.3333333333334,98.73333333333333 730.6666666666667,142.06666666666666 874,155 C 1017.3333333333333,167.93333333333334 1228.6666666666665,150.46666666666667 1440,133 C 1440,133 1440,400 1440,400 Z'
      );
    }
  }

  .path-1 {
    animation: pathAnim-1 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-1 {
    0% {
      d: path(
        'M 0,400 C 0,400 0,266 0,266 C 182,283.6 364,301.2 502,302 C 640,302.8 734,286.8 883,278 C 1032,269.2 1236,267.6 1440,266 C 1440,266 1440,400 1440,400 Z'
      );
    }
    25% {
      d: path(
        'M 0,400 C 0,400 0,266 0,266 C 152.93333333333334,236.26666666666665 305.8666666666667,206.5333333333333 453,220 C 600.1333333333333,233.4666666666667 741.4666666666667,290.1333333333334 905,305 C 1068.5333333333333,319.8666666666666 1254.2666666666667,292.9333333333333 1440,266 C 1440,266 1440,400 1440,400 Z'
      );
    }
    50% {
      d: path(
        'M 0,400 C 0,400 0,266 0,266 C 146.13333333333333,241.73333333333335 292.26666666666665,217.46666666666667 465,226 C 637.7333333333333,234.53333333333333 837.0666666666666,275.8666666666667 1004,288 C 1170.9333333333334,300.1333333333333 1305.4666666666667,283.06666666666666 1440,266 C 1440,266 1440,400 1440,400 Z'
      );
    }
    75% {
      d: path(
        'M 0,400 C 0,400 0,266 0,266 C 214.2666666666667,265.8666666666667 428.5333333333334,265.73333333333335 569,259 C 709.4666666666666,252.26666666666668 776.1333333333332,238.93333333333334 909,239 C 1041.8666666666668,239.06666666666666 1240.9333333333334,252.53333333333333 1440,266 C 1440,266 1440,400 1440,400 Z'
      );
    }
    100% {
      d: path(
        'M 0,400 C 0,400 0,266 0,266 C 182,283.6 364,301.2 502,302 C 640,302.8 734,286.8 883,278 C 1032,269.2 1236,267.6 1440,266 C 1440,266 1440,400 1440,400 Z'
      );
    }
  }
`;

export default Svgwave;
